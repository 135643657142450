.edlio.public {

	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	.hidden {
		display: none;
		visibility: hidden;
		opacity: 0;
	}

	&.sticky-widget {
		position: relative;
		z-index: 998; // Value is 1 less than the value normally used by the slicing team for mobile navigation
		max-width: 320px;
		border-radius: 1em;

		font-size: 1em;
		color: rgb(54,54,54);

		> .bar {
			display: none;
		}

		> .content {
			min-width: 100%;
			width: 100%;
			max-width: 100%;
			padding: 24px;
			background: #fff;
			border-radius: 1.618rem;
			box-shadow: inset 0 0 0 2px rgb(120,120,120);
		}

		@import './reset';
		@import './forms';
		@import './widgets';
		@import './states';
	}
}
