&.collapsible {
	padding: 9px 16px 0 0;

	> .bar {
		display: block;

		.icon {
			justify-content: center;
			align-items: center;

			border-radius: 50%;

			svg {
				height: 100%;
				width: 100%;
			}

			&.expand {
				display: none;

				height: 240px;
				width: 240px;
				padding: 50px;

				background: #fff;
				box-shadow:
					inset 0 0 0 10px rgb(192,192,192),
					10px 10px 38px 0 rgba(0,0,0,.382)
				;

				svg {
					display: none;
					fill: rgb(54,54,54);
				}
			}

			&.minimize {
				position: absolute;
				top: -7px;
				right: 0;

				display: flex;

				height: 44px;
				width: 44px;
				padding: 6px;

				background: none;

				svg {
					fill: #767676;
					background: #fff;
					box-shadow: inset 3px -3px 0px -2px #767676;
					border-radius: 2em;
					height: 24px;
					width: 24px;
				}
			}
		}
	}

	> .content {
		box-shadow: inset 0 0 0 2px rgb(200,200,200);
		min-width: 100%;
		width: 100%;
		padding: 24px;
		max-width: 100%;
		padding: 24px;
		background: #fff;
		border-radius: 1.618rem;
		border: {
			right: 2px solid rgb(120,120,120);
			bottom: 2px solid rgb(120,120,120);
			left: 2px solid rgb(120,120,120);
		}
	}
}

&.minimized {
	padding: 0;
	transform: scale(0.2);

	> .bar {
		height: auto;
		background: transparent;

		.icon {
			&.minimize {
				display: none;
			}

			&.expand {
				display: flex;
			}
		}
	}

	> .content {
		display: none;
	}

	&.right {
		right: 0 !important;
	}

	&.left {
		left: 0 !important;
	}
}

&.right {
	.expand.icon svg.left {
		display: block;
	}

	&.top {
		transform-origin: right top;
	}

	&.bottom {
		transform-origin: right bottom;
	}
}

&.left {
	.expand.icon svg.right {
		display: block;
	}

	&.top {
		transform-origin: left top;
	}

	&.bottom {
		transform-origin: left bottom;
	}
}

&.transition-in {
	transition: all .3s cubic-bezier(0.44, 0.01, 0.37, 0.99);
	transform: scale(1.0);
}

&.transition-out {
	transition: all .3s cubic-bezier(0.44, 0.01, 0.37, 0.99);
	transform: scale(0.2);

	&.right {
		right: 0 !important;
	}

	&.left {
		left: 0 !important;
	}
}
