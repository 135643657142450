.notification {
	width: 100%;
	margin-bottom: .618rem;

	background: #FFDDDB;

	font-size: 0.7rem;
	font-weight: bold;
	line-height: 1.618rem;
	border: 1px solid red;
	box-shadow: none;
}

.notification ~ .form-label{
	font-weight: bold;
}

.field {
	&.error input {
		box-shadow: inset 0 0 0 2px #ed545a;
	}
}

label {
	display: block;
}

input {
	height: 2.382rem;
	width: 100%;
	margin-bottom: .618rem;
	padding: 0 .8rem;

	font-size: 1rem;
	line-height: 2.382rem;

	border: none;
	outline: none;
	box-shadow: inset 0 0 0 2px rgba(120,120,120,.236);
}

.button {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 2.382rem;
	margin-bottom: .618rem;
	padding: 0 .8rem;

	background: rgb(92,92,92);
	border-radius: .236em;

	font-size: 1rem;
	line-height: 2.382rem;
	color: #fff;
	text: {
		align: center;
		decoration: none;
	}

	&:hover {
		background: rgb(112,112,112);
	}

	&:active {
		background: rgb(76,76,76);
	}

	&.disabled {
		background: rgb(192,192,192);
		pointer-events: none;
		cursor: default;
	}
}
