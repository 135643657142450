&.simple-link {
	&.custom-style {
		@import 'global/feature-tools';

		.content {
			background: var(--backgroundColor);
			box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
			border: unset;
			border-radius: 10px;
			padding: 9.6px 20.8px;
			text-align: center;

			@include min-screen-size(small) {
				padding: var(--padding);
			}

			a {
				color: var(--fontColor);

				@include min-screen-size(small) {
					font-size: var(--fontSize);
				}

				font-size: 1em;
			}
		}

		.expand {
			background: var(--backgroundColor) !important;
			box-shadow: 1px 1px 18px 1px rgba(0, 0, 0, 0.25) !important;

			svg {
				fill: var(--fontColor) !important;
			}
		}
	}

	> .content {
		box-shadow: inset 0 0 0 2px rgb(200,200,200);
	}

	a {
		font: {
			size: 1.382em;
			weight: bold;
		}

		color: rgb(54,54,54);
		text-decoration: none;
	}
}

&.signup-form {
	&.custom-style {
		.content {
			background: var(--backgroundColor);
			box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
			border: unset;
			padding: 1em;

			.header {
				border: unset;
				color: var(--titleFontColor);
				text-align: center;
				font-size: 1.3em;
				margin-bottom: unset;
			}
		}

		.field > input {
			border-bottom: var(--fieldBorder);
			box-shadow: unset;
		}

		.button {
			background: var(--buttonBackground);
			color: var(--buttonFontColor);
			font-weight: bold;
			margin-top: 0.85em;
			border-radius: 10px;
		}

		.expand {
			background: var(--backgroundColor) !important;
			box-shadow: 1px 1px 18px 1px rgba(0, 0, 0, 0.25) !important;

			svg {
				fill: var(--titleFontColor) !important;
			}
		}

		.success-notice {
			background: var(--backgroundColor) !important;
			color: var(--titleFontColor) !important;
		}
	}

	> .content {
		padding: {
			top: 0;
			right: 0;
			left: 0;
		}

		.field {
			position: relative;
		}

		.form-input.required:focus ~ .form-label,
		.form-input.valid ~ .form-label,
		.form-input.non-required:focus ~ .form-label{
			top: 0.6rem;
			left:7px;
			font-size: 10px;
			font-weight: bold
		}

		.form-input {
			font-size: 0.9rem;
			padding-top: 1rem;
		}

		.form-input:focus {
			border: 1px solid blue;
		}

		.form-label {
			position: absolute;
			top:0.9rem;
			left:0;
			transition:.5s;
			font-size: 14px;
			padding-left: 6px;
		}
	}
	
	.header {
		margin-bottom: 16px;
		padding: {
			bottom: 8px;
		}

		border-bottom: 2px solid rgba(120,120,120,.236);
	;
		
		font: {
			size: 1.618em;
			weight: bold;
		}

		color: rgb(54,54,54);
	}

	label {
		@extend .hidden;
	}

	.form {
		position: relative;
	}

	.form-label {
		display: inline;
		visibility: inherit;
		opacity: inherit;
	}

	.success-notice {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		
		padding-top: 16px;

		background: #fff;

		.icon {
			flex: 0 0 auto;
			display: flex;
	    align-items: center;
	    justify-content: center;

			height: 3.82em;
	    width: 3.82em;
			margin-bottom: 1em;

			background: rgb(61,163,107);
			border-radius: 50%;

			svg {
				height: 2.382em;
				width: 2.382em;
				fill: #fff;
			}
		}

		.message {
			flex: 0 0 auto;
			font-size: 1em;
			text-align: center;
		}
	}
}
