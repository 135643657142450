// Feature Tools

@import 'themes/default';

@import 'global/theme';

//------------------------------------------------------------------------------
// Import necessary definitions
@import 'definitions/typography';
@import 'definitions/colors';
@import 'definitions/breakpoints';
@import 'definitions/z-index';

//------------------------------------------------------------------------------
// Sass Libraries - use sass globbing for these
// Update this file when new libraries are added!
@import 'libraries/functions';
@import 'libraries/mixins/animations';
@import 'libraries/mixins/flexbox'; // Basic flexbox mixins with browser prefixes
@import 'libraries/mixins/flex_layout'; // For page layout (sidebars, toolbars, etc)
@import 'libraries/mixins/flex_grid'; // For grid-style lists
@import 'libraries/mixins/media-queries';
@import 'libraries/mixins/misc';
@import 'libraries/mixins/typography';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i,800,800i');
$font-family: 'Open Sans' !important;
