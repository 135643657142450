h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 1rem;
	margin: 0 0 1rem 0;
}

h1 {
	font-size: 1.618rem;
}

h2 {
	font-size: 1.382rem;
}

button {
	border: none;
	cursor: pointer;
}
